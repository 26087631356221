<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <CRow>
                <CCol lg="12">
                    <CButton
                        @click="$router.go(-1)"
                        size="sm"
                        color="warning" 
                    > 
                        <font-awesome-icon icon="arrow-left"/> Go back
                    </CButton>
                </CCol>
            </CRow>
            <br>
            <CRow>
                <CCol lg="5">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="cog"/>  
                            Manage Module
                            <font-awesome-icon style="color: #4caf50;" icon="info-circle" size="lg"/>
                            <template v-if="module.status == 'Active'">
                                <CButton 
                                    size="sm"
                                    color="danger" 
                                    class="float-right" 
                                    @click="toggleEdit(), returnInformation()" 
                                    v-show="edit"
                                >
                                    <font-awesome-icon icon="window-close"/> Cancel
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('update-module')"
                                    size="sm"
                                    :disabled="!isValid"
                                    color="success" 
                                    class="float-right" 
                                    v-show="edit" 
                                    @click="updateModule()"
                                >
                                    <font-awesome-icon icon="save"/> Save
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('edit-module')"
                                    size="sm"
                                    color="info" 
                                    class="float-right" 
                                    @click="toggleEdit" v-show="!edit"
                                >
                                    <font-awesome-icon icon="edit"/> Edit
                                </CButton>
                            </template>
                            
                            <template v-if="module.status == 'Archived'">
                                <CButton 
                                    v-if="$store.getters['can']('restore-module')"
                                    size="sm"
                                    color="success" 
                                    class="float-right" 
                                    @click="restore()"
                                >
                                <font-awesome-icon icon="trash-restore"/> Restore
                                </CButton>
                            </template>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol lg="10">
                                    <label>Please select group</label>
                                    <v-select 
                                        :disabled="disable == 1 ? true : false"
                                        label="name"
                                        v-model="module.group"
                                        :options="group_lists"
                                        :class="!module.group ? 'has-error' : ((disable) ? '': 'has-success')"
                                    />
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol lg="10">
                                    Module Type &nbsp;&nbsp; 
                                   <a-popover title="NOTE:" placement="left">
                                        <template slot="content">
                                            <ModuleTypeHelp/>
                                        </template>
                                        <font-awesome-icon 
                                            style="color: #468dc8;" 
                                            icon="question-circle" 
                                        />
                                    </a-popover>
                                </CCol>
                                <CCol lg="10">
                                    <p-radio class="p-icon p-round p-jelly" 
                                        :disabled="disable == 1 ? true : false"
                                        name="type" 
                                        color="warning" 
                                        value="basic" 
                                        v-model="module.type"
                                    >
                                        Basic 
                                    </p-radio>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <p-radio class="p-icon p-round p-jelly" 
                                        :disabled="disable == 1 ? true : false"
                                        name="type" 
                                        value="advanced" 
                                        color="danger"  
                                        v-model="module.type"
                                    >
                                        Advanced
                                    </p-radio>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol lg="10">
                                    <CInput 
                                        :disabled="disable == 1 ? true : false"
                                        :lazy="false"
                                        :value.sync="$v.module.name.$model"
                                        :isValid="checkIfValid('name')"
                                        label="Name" 
                                        type="text" 
                                        placeholder="User" 
                                        autocomplete="off"  
                                        v-model="module.name"
                                        v-nospecialcharacter
                                        v-tocapitalize
                                        readonly
                                    >
                                        <template #append-content>
                                        <a-popover title="NOTE!">
                                                <template slot="content">
                                                <p>Use singular word.</p>
                                                </template>
                                                    <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                            </a-popover>
                                        </template>
                                    </CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="10">
                                    <CInput 
                                        :lazy="false"
                                        :value.sync="$v.module.url.$model"
                                        :isValid="checkIfValid('url')"
                                        label="Url" 
                                        type="text" 
                                        placeholder="user" 
                                        autocomplete="off"  
                                        v-model="module.url"
                                        readonly
                                    >
                                    </CInput>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="10">
                                    <CInput 
                                        :lazy="false"
                                        :value.sync="$v.module.icon.$model"
                                        :isValid="checkIfValid('icon')"
                                        label="Select Icon" 
                                        type="text" 
                                        placeholder="Select icon" 
                                        autocomplete="off" 
                                        readonly 
                                        v-model="module.icon"
                                    >
                                        <template #prepend-content>
                                        <CIcon :name="module.icon"/>
                                    </template>
                                    
                                    <template #append>
                                        <CButton 
                                            color="primary"  
                                            @click="$refs.iconModal.modal = true, emitIconModal()"
                                            :disabled="disable == 1 ? true : false"
                                        >
                                        <font-awesome-icon icon="search"/>
                                        </CButton>
                                    </template>
                                    </CInput>
                                    
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <CTextarea
                                        :disabled="disable == 1 ? true : false"
                                        label="Description"
                                        placeholder="Content..."
                                        autocomplete="off"  
                                        rows="3"
                                        v-model="module.description"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <label>Status</label>: <CBadge :color="getColorStatus(module.status)">{{module.status}}</CBadge>
                                </CCol>
                            </CRow>
                            
                            <CRow v-show="edit">
                                <CCol lg="12">
                                    <CCard borderColor="danger" v-if="$store.getters['can']('archive-module')">
                                        <CCardHeader>
                                            <font-awesome-icon 
                                                icon="exclamation" 
                                                :style="{ color: 'red' }"
                                            /> 
                                            Danger Zone
                                        </CCardHeader>
                                        <CCardBody>
                                            <CRow>
                                                <CCol lg="8">
                                                    <h6>Archive this Module ?</h6>
                                                </CCol>
                                                <CCol lg="12">
                                                    <label> Once archived, this module cannot used anymore. Please be certain.</label>
                                                </CCol>
                                            </CRow>
                                            <br>
                                            <CRow>
                                                <CCol lg="5">
                                                    <CButton shape="pill" color="warning" @click="archive()" > 
                                                        <font-awesome-icon icon="minus-square" /> Archived 
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol lg="7">
                    <CCard>
                        <CCardHeader>
                            <font-awesome-icon icon="list-ol"/>  
                            Module Access 
                            <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                        </CCardHeader>
                        <CCardBody>
                            
                            <CRow v-if="module.name && module.url">
                                <CCol lg="3">Name</CCol>
                                <CCol lg="4">Slug</CCol>
                                <CCol lg="4">URL</CCol>
                                <CCol lg="1"></CCol>
                            </CRow>
                            <CRow v-if="module.name && module.url">
                                <CCol lg="3">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            type="text" 
                                            placeholder="Create User" 
                                            autocomplete="off"  
                                            v-model="custom_module_access.name"
                                        />
                                </CCol>
                                <CCol lg="4">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            type="text" 
                                            placeholder="create-user" 
                                            autocomplete="off"  
                                            v-model="custom_module_access.slug"
                                        />
                                </CCol>
                                <CCol lg="4">
                                        <CInput 
                                            :disabled="disable == 1 ? true : false"
                                            type="text" 
                                            placeholder="create" 
                                            autocomplete="off"  
                                            v-model="custom_module_access.url"
                                        >
                                            <template #prepend-content>
                                            /
                                        </template>
                                        </CInput>
                                </CCol>
                                <CCol lg="1">
                                        <CButton 
                                            :disabled="disable == 1 ? true : false"
                                            class="float-right"
                                            type="button" 
                                            color="primary"
                                            @click="addCustomModuleAccess()"
                                        >
                                            <font-awesome-icon icon="plus-square"/> 
                                        </CButton>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <CDataTable
                                        :items="module.module_access"
                                        :fields="access_lists_fields"
                                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                        :items-per-page="10"
                                        border
                                        :tableFilter="false"
                                        sorter
                                        pagination
                                    >
                                        <template #url="{item}">
                                            <td>
                                                {{item.url ? `${module.url}${item.url}` : ''}}
                                            </td>
                                        </template>
                                        <template #type="{item}">
                                            <td>
                                                {{item.url ? 'Component' : 'API'}}
                                            </td>
                                        </template>
                                        <template #action="{item}" >
                                            <td style="width: 10%;" >
                                                <center >
                                                    <CButton 
                                                        color="danger" 
                                                        @click="removeAccess(item)" 
                                                        shape="pill" 
                                                        size="sm"
                                                    >
                                                        <font-awesome-icon icon="times"/>
                                                    </CButton>
                                                </center>
                                            </td>
                                        </template>
                                    </CDataTable>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <IconsModal ref="iconModal"/>
        </div>
    </div>
</template>
<script>
import IconsModal from '../../modals/IconsModal'
import { required,minLength } from "vuelidate/lib/validators"
import ModuleTypeHelp from './components/ModuleTypeHelp.vue';
export default {
    name: 'ModuleEdit',
    components: {
        IconsModal,
        ModuleTypeHelp,
    },
    data() {
        return {
            disable: 1,
            edit: false,
            group_lists: [],
            module: {
                id: '',
                icon: 'cil-folder',
                group:{
                    id:null,
                    name:null,
                },
                name: null,
                url: null,
                type:null,
                description: null,
                module_access: [],
                status: ''
            },
            added_access: [],
            removed_access: [],
            
            module_backup: {
                id: '',
                icon: 'cil-folder',
                group:{
                    id:null,
                    name:null,
                },
                name: null,
                url: null,
                type:null,
                description: null,
                type: null,
                module_access: [],
                status: ''
            },
            custom_module_access: {
                name:'',
                url:'',
                slug:'',
            },
        }
    },
    created() {
        this.$Progress.start()
        this.module.id = this.$route.params.id
        this.getModule();
        this.getGroups();
        this.$Progress.finish()
    },
    mounted() {
        this.$watch(
            "$refs.iconModal.selected",
            (new_value, old_value) => (this.module.icon = new_value)
        )
    },
    validations: {
        module: {
            icon: { required, },
            name: { required, },
            url: { required, },
            type: { required, },
            module_access: { required, minLength: minLength(1) },
        }
    },
    computed: {
        isValid () { return !this.$v.module.$invalid },
        isDirty () { return this.$v.module.$anyDirty },
        access_lists_fields: function() {
            let custom_fields= [
                { key: 'name', sorter: false},
                { key: 'slug', sorter: false},
                { key: 'url',  sorter: false},
                { key: 'type',  sorter: false},
            ]
            if(!this.disable) {
                custom_fields.push({ key: 'action',  sorter: false})
            }
            return custom_fields;
        }
    },
    methods: {
        validate: function () {
            this.$v.$touch()
        },
        validateCustomAccess: function() {
            if(this.custom_module_access.name == '' || this.custom_module_access.name == null &&
                this.custom_module_access.slug == '' || this.custom_module_access.slug == null
                ) return false;
            return true;
        },
        validateModule:function () {
            if(this.module.module_access.length <= 0) {
                    return false;
            } 
            return true;
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.module[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.validate();
            }
            else {
                this.$v.$reset()
            }
        },
        removeAccess: function(item) {
            let old =false;
            let added =false;
            for(let j = 0; j < this.added_access.length; j++) {
                if( this.added_access[j].name == item.name &&
                    this.added_access[j].url == item.url &&
                    this.added_access[j].slug == item.slug
                ) {
                    this.added_access.splice(j, 1)
                    added = true;
                }
            }

            for(let i = 0; i < this.module.module_access.length; i++) {
                if( this.module.module_access[i].name == item.name &&
                    this.module.module_access[i].url == item.url &&
                    this.module.module_access[i].slug == item.slug
                ) {
                    this.module.module_access.splice(i, 1)
                    old = true;
                    break;
                }
            }

            if(!added && old) {
                let removed_access = {
                    name: item.name,
                    slug: item.slug,
                    url: item.url,
                }
                this.removed_access.push(removed_access)
            }
        },
        emitIconModal: function() {
            this.$emit('show_icons_modal');
        },
        returnInformation: function () {
            // ! DEEP CLONE //
            this.module = Vue.util.extend({}, this.module_backup)
            this.module.module_access = Vue.util.extend([], this.module_backup.module_access)
            this.added_access = [];
            this.removed_access = [];
        },
        getGroups: function() {
            axios.get('/groups/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            name: response.data.data[i].name
                        }
                        this.group_lists.push(option)
                    }
                }
            })
        },
        getModule: function() {
            axios.get('/modules/' + this.module.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.assignData(response.data)
                }
            })
        }, 
        assignData:function(response) { 
            this.module = response.data;
            this.module.status = response.data.deleted_at ? 'Archived' : 'Active';

            // ! DEEP CLONE //
            this.module_backup = Vue.util.extend({}, response.data)
            this.module_backup.module_access = Vue.util.extend([], response.data.module_access)
        },
        addCustomModuleAccess: function() {
            if(!this.validateCustomAccess()) {
                return this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'error',
                        title: 'Name and Slug cannot be empty.',
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
            }
            let url = this.custom_module_access.url ?
                     `/${this.custom_module_access.url.replace(/\\|\//g,'')}` :
                     null;

            if(!this.checkIfAccessExists(url, this.custom_module_access.slug, 'custom')) {
                let custom_access = {
                    name: this.custom_module_access.name,
                    slug: this.custom_module_access.slug,
                    url: url,
                }
                this.module.module_access.push(custom_access)
                this.added_access.push(custom_access)
                this.custom_module_access = this.clearCustomAccess();
            }

        },
        checkIfAccessExists: function(url, slug, type) {
            for(let i = 0; i < this.module.module_access.length; i++) {
                if( this.module.module_access[i].url == url &&
                    this.module.module_access[i].slug == slug
                ) {
                    if(type == 'custom')
                    {
                        return this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'error',
                            title: 'Access already exists in the list.',
                            showConfirmButton: false,
                            timerProgressBar: true,
                        }) 
                    }
                }
            }
        },
        clearCustomAccess: function() {
           return {
               name: '',
               slug: '',
               url: '',
           }
        },
        updateModule: function() {
            if(JSON.stringify(this.module) === JSON.stringify(this.module_backup)) {
                this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }         
            if(!this.validateModule()){
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to update a module.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        group_id: this.module.group.id,
                        icon: this.module.icon,
                        description: this.module.description,
                        type: this.module.type,
                        added_access: this.added_access,
                        removed_access: this.removed_access
                    }
                    return axios.post('/modules/update/' + this.module.id, data, {validateStatus: () => true})
                    .then(response => {
                        if( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `${this.module.name} has been updated.`,
                            }).then(() => {
                                this.assignData(response.data)
                                this.toggleEdit()
                                this.$Progress.finish()
                            })
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        },
        archive: function() {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to archive <b>${this.module.name}</b> module `,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                }).then((result) => {
                if(result.value){
                    this.remove()
                }else{
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        remove: function() {
            this.$Progress.start()
            axios.post('/modules/archive/' + this.module.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.module.name} Module successfully archived.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }).then( () => {
                        this.module.status = 'Archived';
                        this.toggleEdit()
                        this.$Progress.finish()
                    }) 
                }
            })
        },
        restore: function () {
            this.$Progress.start()
            axios.post('/modules/restore/' + this.module.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.module.name} Module successfully restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }).then( () => {
                        this.module = Vue.util.extend({}, this.module_backup)
                        this.module.status =  'Active';
                        this.$Progress.finish()
                    }) 
                }
            })
        },
    },
}
</script>