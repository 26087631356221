<template>
    <small>
      <strong>Basic -</strong> <i>This module does not contain any confidential nor administrator privilages to used. Best for Default users.</i> <br>
      <strong>Advance -</strong> <i>This module has confidential data and or needed some administrator privilages. Best for Super users.</i> <br>
  </small>
  </template>
  
  <script>
  export default {
      name: 'ModuleTypeHelp'
  }
  </script>
  
  <style>
  
  </style>